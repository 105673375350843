/**
 * Copy the given text to the clipboard.
 *
 */
export async function copyToClipboard(text: string): Promise<void> {
  if (!navigator.clipboard) {
    // Fallback for browsers that do not support the Clipboard API
    const textArea = document.createElement('textarea');
    textArea.value = text;
    textArea.style.position = 'fixed'; // Prevent scrolling to the bottom of the page in MS Edge
    textArea.style.left = '-9999px';
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      document.execCommand('copy');
      document.body.removeChild(textArea);
    } catch (err) {
      document.body.removeChild(textArea);
      throw new Error('Failed to copy text to clipboard.');
    }
  } else {
    // Modern approach using the Clipboard API
    try {
      await navigator.clipboard.writeText(text);
    } catch (err) {
      throw new Error('Failed to copy text to clipboard.');
    }
  }
}
