import { NgFor, NgIf, NgSwitch, NgSwitchCase, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ChangePasswordUseCase } from 'src/app/core/usecases/auth/change-password.usecase';
import { LocalizedComponent } from '../../base/localized.component';
import { ProfileMainHeaderComponent } from '../shared/components/profile-main-header/profile-main-header.component';
import { profileComponentsAssetsPathToken } from '../shared/config/profile.component.assets.path.config';
import nativeFormControlValuesMustMatchValidator from '../shared/validators/native-form-formcontrol-values-must-match.validators';

@Component({
  selector: 'app-change-password',
  styleUrls: ['change-password.component.scss'],
  templateUrl: 'change-password.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ProfileMainHeaderComponent,
    FormsModule,
    ReactiveFormsModule,
    NgTemplateOutlet,
    NgIf,
    NgFor,
    NgSwitch,
    NgSwitchCase,
  ],
})
export class ChangePasswordComponent extends LocalizedComponent implements OnInit, OnDestroy {
  public changePasswordForm: UntypedFormGroup;

  public changePasswordFormIsSubmitted: boolean;

  public onDestroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private toastrService: ToastrService,
    private changePasswordUseCase: ChangePasswordUseCase,
    @Inject(profileComponentsAssetsPathToken) public assetsPath: string,
  ) {
    super();
  }

  ngOnInit(): void {
    this.initializeChangePasswordForm();
  }

  initializeChangePasswordForm(): void {
    this.changePasswordForm = new UntypedFormGroup(
      {
        currentPassword: new UntypedFormControl('', [Validators.required]),
        newPassword: new UntypedFormControl('', [Validators.required, Validators.minLength(9)]),
        confirmPassword: new UntypedFormControl('', [Validators.required, Validators.minLength(9)]),
      },
      [nativeFormControlValuesMustMatchValidator(['newPassword', 'confirmPassword'])],
    );
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  public onChangeNewPasswordFormSubmit(): void {
    this.changePasswordFormIsSubmitted = true;
  }

  public returnFormFieldErrorsAsAnArray(
    fieldErrorsObject: { [error: string]: boolean },
    crossFormErrors: { [error: string]: boolean } = {},
  ): Array<string> {
    const errors: Array<string> = [];
    const combinedErrors = { ...crossFormErrors, ...fieldErrorsObject };
    for (const key in combinedErrors) {
      if (combinedErrors[key]) {
        errors.push(key);
      }
    }
    return errors;
  }

  public getErrorMappingForFormFields(field: string): any {
    const errors: any = {
      currentPassword: {
        required: this.trans('user.currentPasswordRequired'),
      },
      newPassword: {
        required: this.trans('user.newPasswordRequired'),
        fieldsThatMustMatchDoMatchError: this.trans('user.passwordsDoNotMatch'),
        minlength: this.trans('validation.minLength', { length: 9 }),
      },
      confirmPassword: {
        required: this.trans('user.confirmPasswordRequired'),
        fieldsThatMustMatchDoMatchError: this.trans('user.passwordsDoNotMatch'),
        minlength: this.trans('validation.minLength', { length: 9 }),
      },
    };
    return errors[field];
  }

  onSubmitChangePasswordForm(): void {
    this.changePasswordFormIsSubmitted = true;
    if (this.changePasswordForm.invalid) {
      return;
    }
    this.changePasswordUseCase
      .execute(this.changePasswordForm.value)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe({
        next: (_) => {
          this.showNotificationMessage('success', this.trans('user.passwordChangedSuccessfully'));
        },
        error: (err) => {
          if (err.status === 403) {
            this.showNotificationMessage('error', this.trans('auth.invalidPassword'));
          } else {
            this.showNotificationMessage('error', this.trans('somethingWentWrong'));
          }
        },
      });
  }

  showNotificationMessage(status: 'success' | 'error', message: string): void {
    switch (status) {
      case 'success':
        this.toastrService.success(message);
        break;
      case 'error':
        this.toastrService.error(message);
    }
  }
}
