import { Component, inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { appUrlsConstantsInjectionToken } from 'src/app/data/injection-tokens/app-urls-constants.injection-token';
import { BaseComponent } from 'src/app/presentation/base/base.component';
import { GoToWalletPresenter } from './presenter/go-to-wallet.presenter';
import { GoToWalletSideEffect } from './presenter/go-to-wallet.sideEffects';
import { GoToWalletViewEvents } from './presenter/go-to-wallet.viewEvents';
import { GoToWalletViewState } from './presenter/go-to-wallet.viewState';

@Component({
  selector: 'app-go-to-wallet-dialog',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './go-to-wallet-dialog.component.html',
  styleUrls: ['./go-to-wallet-dialog.component.scss'],
  providers: [GoToWalletPresenter],
})
export class GoToWalletDialogComponent extends BaseComponent<
  GoToWalletPresenter,
  GoToWalletViewState,
  GoToWalletViewEvents,
  GoToWalletSideEffect
> {
  public presenter: GoToWalletPresenter = inject(GoToWalletPresenter);

  private _dialogRef: MatDialogRef<GoToWalletDialogComponent> = inject(
    MatDialogRef<GoToWalletDialogComponent>,
  );

  private _router: Router = inject(Router);

  private _appURLs: { [url: string]: string } = inject(appUrlsConstantsInjectionToken);

  onSideEffect(sideEffect: GoToWalletSideEffect): void {
    switch (sideEffect.type) {
      case 'NavigateToWallet': {
        this._dialogRef.close();
        this._router.navigate([this._appURLs.WALLET_URL]);
        break;
      }
      case 'CloseGoToWalletDialog': {
        this._dialogRef.close();
        break;
      }
    }
  }
}
