<div class="referred-account">
  <div class="referred-account__main-header-with-button">
    <div class="referred-account__main-header-with-button__main-header">
      <app-profile-main-header
        [headerText]="'PROFILE.REFERRED_ACCOUNTS.TITLE' | translate"
        [subHeaderText]="'PROFILE.REFERRED_ACCOUNTS.SUBTITLE' | translate"
        [iconUrl]="assetsPath + '/img/referrals-i.png'"
      ></app-profile-main-header>
    </div>
    <ng-template
      [ngTemplateOutlet]="copyReferralLinkTemplate"
      [ngTemplateOutletContext]="{
        cssClass: 'btn btn-referrals',
        buttonLabel: 'PROFILE.REFERRED_ACCOUNTS.REFERRAL_LINK' | translate
      }"
    ></ng-template>
  </div>
  <div class="referred-account__main-data">
    <ng-template
      [ngTemplateOutlet]="copyReferralLinkTemplate"
      [ngTemplateOutletContext]="{
        cssClass: 'btn btn-referrals-mobile',
        buttonLabel: 'PROFILE.REFERRED_ACCOUNTS.REFERRAL_LINK' | translate
      }"
    ></ng-template>
    <div class="referred-account__main-data__referrals-main">
      <ng-template
        [ngTemplateOutlet]="referralsMainSubdivContentTemplate"
        [ngTemplateOutletContext]="{
          miniTitle: 'PROFILE.REFERRED_ACCOUNTS.NUMBER_OF_ACCOUNTS' | translate,
          referralsTitle: countUsers
        }"
      ></ng-template>
      <ng-template
        [ngTemplateOutlet]="referralsMainSubdivContentTemplate"
        [ngTemplateOutletContext]="{
          miniTitle: 'PROFILE.REFERRED_ACCOUNTS.NUMBER_OF_ORDERS' | translate,
          referralsTitle: countOrders
        }"
      ></ng-template>
    </div>
  </div>
</div>

<!-- shared templates -->
<ng-template #copyReferralLinkTemplate let-cssClass="cssClass" let-buttonLabel="buttonLabel">
  <button [class]="cssClass" (click)="copyReferralLink()">{{ buttonLabel }}</button>
</ng-template>

<ng-template
  #referralsMainSubdivContentTemplate
  let-miniTitle="miniTitle"
  let-referralsTitle="referralsTitle"
>
  <div class="referred-account__main-data__referrals-main__subdiv">
    <div class="referred-account__main-data__referrals-main__subdiv__mini-title">
      {{ miniTitle }}
    </div>
    <div class="referred-account__main-data__referrals-main__subdiv__referrals-title">
      {{ referralsTitle }}
    </div>
  </div>
</ng-template>
