import { NgIf } from '@angular/common';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatTabsModule } from '@angular/material/tabs';
import { UserModel } from 'src/app/core/domain/user/user.model';
import { CheckUserFeatureExistsUseCase } from 'src/app/core/usecases/user/check-user-feature-exists.usecase';
import { WALLET_FEATURE } from 'src/app/presentation/shared/constants/index';
import { BaseComponent } from '../base/base.component';
import { StatefulState } from '../base/stateful.presenter';
import { LoaderComponent } from '../shared/components/loader/loader.component';
import { AccountPresenter } from './account.presenter';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ChangeWalletPasswordComponent } from './change-wallet-password/change-wallet-password.component';
import { DeleteAccountComponent } from './delete-account/delete-account.component';
import { GetSessionTokenComponent } from './get-session-token/get-session-token.component';
import { PersonalInfoComponent } from './personal-info/personal-info.component';
import { ProfileCardComponent } from './profile-card/profile-card.component';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
  standalone: true,
  providers: [AccountPresenter],
  imports: [
    NgIf,
    MatTabsModule,
    ProfileCardComponent,
    PersonalInfoComponent,
    ChangePasswordComponent,
    ChangeWalletPasswordComponent,
    LoaderComponent,
    DeleteAccountComponent,
    GetSessionTokenComponent,
  ],
})
export class AccountComponent
  extends BaseComponent<AccountPresenter, StatefulState<UserModel, string>>
  implements OnInit, OnDestroy
{
  public changePasswordForm: UntypedFormGroup;

  public canChangeWalletPassword = false;

  public presenter: AccountPresenter = inject(AccountPresenter);

  private _checkUserFeatureExistsUseCase: CheckUserFeatureExistsUseCase = inject(
    CheckUserFeatureExistsUseCase,
  );

  protected onInit(): void {
    this.presenter.emitViewEvent({ type: 'Init' });
    this.initForm();
    this.canChangeWalletPassword = this._checkUserFeatureExistsUseCase.execute(WALLET_FEATURE);
  }

  private initForm(): void {
    this.changePasswordForm = new UntypedFormGroup(
      {
        currentPassword: new UntypedFormControl('', [Validators.required]),
        newPassword: new UntypedFormControl('', [Validators.required, Validators.minLength(9)]),
        confirmPassword: new UntypedFormControl('', [Validators.required]),
      },
      { validators: this.passwordMatchCheck.bind(this) },
    );
  }

  private passwordMatchCheck(formGroup: UntypedFormGroup): { passwordNotMatch: boolean } | null {
    const { value: newPassword } = formGroup.get('newPassword')!;
    const { value: confirmPassword } = formGroup.get('confirmPassword')!;
    return newPassword === confirmPassword ? null : { passwordNotMatch: true };
  }
}
