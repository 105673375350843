import { NgIf } from '@angular/common';
import { Component, OnDestroy } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { mixPanelEvent } from '@features/activities/data';
import { dialogAtom } from '@presentation/shared/dialog';
import { copyToClipboard } from '@presentation/shared/utils';
import { sessionAtom } from '@presentation/user/atoms/account';
import { LocalizedComponent } from 'app/presentation/base/localized.component';
import { toastSuccess } from '../../../../v3/presentation/shared/toast';
import { GetSessionTokenDialogComponent } from './get-session-token-dialog/get-session-token-dialog.component';

@Component({
  selector: 'app-get-session-token',
  standalone: true,
  imports: [MatDialogModule, NgIf],
  templateUrl: './get-session-token.component.html',
  styleUrls: ['./get-session-token.component.scss'],
  providers: [],
})
export class GetSessionTokenComponent extends LocalizedComponent implements OnDestroy {
  public showToken = false;

  public sessionAtom = sessionAtom;

  public onCopy(): void {
    copyToClipboard(this.sessionAtom.get('sessionToken'));

    toastSuccess(this.trans('user.sessionTokenCopied'));

    mixPanelEvent('session_token_copied');
  }

  public toggleDialog(): void {
    dialogAtom.open(GetSessionTokenDialogComponent, {
      id: 'get-session-token-dialog',
      maxWidth: '478px',
      backdropClass: 'backdrop-bg',
      direction: this.direction,
    });

    mixPanelEvent('session_token_opened_dialog');
  }

  public ngOnDestroy(): void {
    sessionAtom.reset();
  }
}
