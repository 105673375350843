import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-profile-main-header',
  styleUrls: ['profile-main-header.component.scss'],
  templateUrl: 'profile-main-header.component.html',
  standalone: true,
})
export class ProfileMainHeaderComponent {
  @Input() iconUrl: string;

  @Input() headerText: string;

  @Input() subHeaderText: string;
}
