import { inject } from '@angular/core';
import { trans } from '@mongez/localization';
import { UserModel } from '../../core/domain/user/user.model';
import { LogMixpanelEventUseCase } from '../../core/usecases/analytics/log-mixpanel-event.usecase';
import { EventWithType, ViewEventHandler } from '../base/base.presenter';
import { FetchPresenter } from '../base/fetch.presenter';
import { StatefulState } from '../base/stateful.presenter';
import { ProfileService } from '../shared/services/profile.service';

export type AccountViewState = StatefulState<UserModel, any> & {
  isAccountDeletionEnabled: boolean;
  canChangeWalletPassword: boolean;
};

export class AccountPresenter extends FetchPresenter<AccountViewState> {
  protected _logMixpanelEventUseCase: LogMixpanelEventUseCase = inject(LogMixpanelEventUseCase);

  protected _profileService: ProfileService = inject(ProfileService);

  protected viewEvents?: ViewEventHandler<EventWithType> = {
    Init: this.loadProfile,
  };

  private loadProfile(): void {
    this.startLoading();

    this._profileService.getUserProfile().subscribe({
      next: (res) => {
        this.success(res.data);
      },
      error: (err) => {
        this.failed(err);
      },
    });
  }

  public tabClicked(tabLabel: string): void {
    const accountDeletionTabName = trans('PROFILE.TABS.DELETE_ACCOUNT');

    if (tabLabel === accountDeletionTabName) {
      this._logMixpanelEventUseCase.execute({
        eventName: 'delete_tab_clicked',
      });
    }
  }

  public updateUserData(event: any): void {
    this.setData(event);
  }
}
