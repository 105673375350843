import { Clipboard } from '@angular/cdk/clipboard';
import { NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ReferralsModel } from 'src/app/core/domain/referrals.model';
import { UserModel } from 'src/app/core/domain/user/user.model';
import { GetReferralsUseCase } from 'src/app/core/usecases/user/get-referrals.usecase';
import { ProfileMainHeaderComponent } from '../shared/components/profile-main-header/profile-main-header.component';
import { profileComponentsAssetsPathToken } from '../shared/config/profile.component.assets.path.config';

@Component({
  selector: 'app-referred-account',
  styleUrls: ['referred-account.component.scss'],
  templateUrl: 'referred-account.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ProfileMainHeaderComponent, NgTemplateOutlet, TranslateModule],
})
export class ReferredAccountComponent implements OnInit, OnDestroy {
  @Input() user: UserModel;

  public countOrders: number;

  public countUsers: number;

  private onDestroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    @Inject(profileComponentsAssetsPathToken) public assetsPath: string,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private clipboard: Clipboard,
    private toastrService: ToastrService,
    private getReferralsUseCase: GetReferralsUseCase,
    private _translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.getReferrals();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  public copyReferralLink(): void {
    const userReferralLink = `https://${window.location.hostname}/register?referral=${this.user.referralCode}`;
    this.clipboard.copy(userReferralLink);
    this.toastrService.info('Copied to clipboard');
  }

  private getReferrals(): void {
    this.getReferralsUseCase
      .execute()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe({
        next: (referrals: ReferralsModel) => {
          this.countUsers = referrals.countUsers;
          this.countOrders = referrals.countOrders;
          this.changeDetectorRef.detectChanges();
        },
        error: (err) => {
          this.toastrService.error(
            this._translateService.instant('PROFILE.REFERRED_ACCOUNTS.ERROR_OCCURED_ACCOUNT_LIST'),
          );
        },
      });
  }
}
