<div class="go-to-wallet">
  <p class="content-main-color body2--medium">{{ 'DELETE_ACCOUNT.WITHDRAWAL' | translate }}</p>
  <div class="go-to-wallet__buttons">
    <button
      class="wallet-buttons wallet-buttons__cancel body2--bold"
      (click)="
    presenter.emitViewEvent({
      type: 'ClickCancel',
    })
  "
    >
      {{ 'DELETE_ACCOUNT.CANCEL' | translate }}
    </button>
    <button
      class="wallet-buttons wallet-buttons__navigate body2--bold"
      (click)="
    presenter.emitViewEvent({
      type: 'ClickGoToWallet',
    })
  "
    >
      {{ 'DELETE_ACCOUNT.GO_TO_WALLET' | translate }}
    </button>
  </div>
</div>
