<div class="container-div">
  <div class="title">{{ trans('PROFILE.ACCOUNT_SETTINGS') }}</div>
  <mat-tab-group
    mat-align-tabs="start"
    disableRipple="true"
    dynamicHeight="true"
    *ngIf="!presenter.isLoading"
    (selectedTabChange)="presenter.tabClicked($event.tab.textLabel)"
  >
    <mat-tab label="{{ trans('PROFILE.TABS.PERSONAL_INFO') }}">
      <ng-template matTabContent>
        <div class="main">
          <app-personal-info
            [user]="viewState.data"
            (userUpdated)="presenter.updateUserData($event)"
          ></app-personal-info>
        </div>
      </ng-template>
    </mat-tab>
    <mat-tab label="{{ trans('PROFILE.TABS.CHANGE_PASSWORD') }}">
      <ng-template matTabContent>
        <div class="main">
          <app-change-password></app-change-password>
        </div>
      </ng-template>
    </mat-tab>
    <mat-tab
      label="{{ trans('PROFILE.TABS.CHANGE_WALLET_PASSWORD') }}"
      *ngIf="canChangeWalletPassword"
    >
      <ng-template matTabContent>
        <div class="main">
          <app-change-wallet-password></app-change-wallet-password>
        </div>
      </ng-template>
    </mat-tab>
    <mat-tab label="{{ trans('PROFILE.TABS.DELETE_ACCOUNT') }}">
      <ng-template matTabContent>
        <div class="main">
          <app-delete-account></app-delete-account>
        </div>
      </ng-template>
    </mat-tab>
    <mat-tab label="{{ trans('PROFILE.TABS.GET_SESSION_TOKEN') }}">
      <ng-template matTabContent>
        <div class="main">
          <app-get-session-token></app-get-session-token>
        </div>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
<loader [loading]="presenter.isLoading" size="md"></loader>
