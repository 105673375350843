import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, OnDestroy, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ChangeWalletPasswordUseCase } from 'src/app/core/usecases/wallet/change-wallet-password.usecase';
import { LocalizedComponent } from '../../base/localized.component';
import { FormFieldErrorComponent } from '../shared/components/form-field-error/form-field-error.component';
import { ProfileMainHeaderComponent } from '../shared/components/profile-main-header/profile-main-header.component';
import { profileComponentsAssetsPathToken } from '../shared/config/profile.component.assets.path.config';
import nativeFormControlValuesMustMatchValidator from '../shared/validators/native-form-formcontrol-values-must-match.validators';

@Component({
  selector: 'app-change-wallet-password',
  styleUrls: ['change-wallet-password.component.scss'],
  templateUrl: 'change-wallet-password.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ProfileMainHeaderComponent,
    FormsModule,
    ReactiveFormsModule,
    NgTemplateOutlet,
    FormFieldErrorComponent,
    TranslateModule,
  ],
})
export class ChangeWalletPasswordComponent extends LocalizedComponent implements OnInit, OnDestroy {
  public changeWalletPasswordForm: FormGroup;

  public changeWalletPasswordFormIsSubmitted = false;

  private _toasterService = inject(ToastrService);

  private _changeWalletPasswordUseCase = inject(ChangeWalletPasswordUseCase);

  public assetsPath: string = inject(profileComponentsAssetsPathToken);

  private onDestroy$: Subject<boolean> = new Subject<boolean>();

  ngOnInit(): void {
    this.instantiateChangeWalletPasswordForm();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
    this.unsubscribeAll();
  }

  public getErrorMappingForFormFields(field: string): any {
    const errors: any = {
      currentWalletPassword: {
        required: this.trans('PROFILE.CHANGE_WALLET_PASSWORD.PREVIOUS_PASSWORD_REQUIRED'),
      },
      newWalletPassword: {
        required: this.trans('PROFILE.CHANGE_WALLET_PASSWORD.NEW_PASSWORD_REQUIRED'),
        fieldsThatMustMatchDoMatchError: this.trans(
          'PROFILE.CHANGE_WALLET_PASSWORD.FIELDS_MUST_MATCH',
        ),
        minlength: this.trans('PROFILE.ACCOUNT_DETAILS.PASSWORD_LENGTH_ERROR'),
      },
      confirmWalletPassword: {
        required: this.trans('PROFILE.CHANGE_WALLET_PASSWORD.CONFIRM_NEW_PASSWORD_REQUIRED'),
        fieldsThatMustMatchDoMatchError: this.trans(
          'PROFILE.CHANGE_WALLET_PASSWORD.FIELDS_MUST_MATCH',
        ),
        minlength: this.trans('PROFILE.ACCOUNT_DETAILS.PASSWORD_LENGTH_ERROR'),
      },
    };
    return errors[field];
  }

  public onSubmitChangeWalletPasswordForm(): void {
    this.changeWalletPasswordFormIsSubmitted = true;
    if (this.changeWalletPasswordForm.valid) {
      const useCaseParams = {
        currentWalletPassword: this.changeWalletPasswordForm.get('currentWalletPassword')!.value,
        newWalletPassword: this.changeWalletPasswordForm.get('newWalletPassword')!.value,
        confirmWalletPassword: this.changeWalletPasswordForm.get('confirmWalletPassword')!.value,
      };
      this._changeWalletPasswordUseCase
        .execute(useCaseParams)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe({
          next: () => this.showNotificationMessage(200),
          error: (err) => {
            this.showNotificationMessage(err.status);
          },
        });
    }
  }

  showNotificationMessage(code: number): void {
    switch (code) {
      case 200:
        this._toasterService.success(this.trans('PROFILE.ACCOUNT_DETAILS.PASSWORD_MODIFIED'));
        break;
      case 403:
        this._toasterService.error(this.trans('PROFILE.ACCOUNT_DETAILS.PASSWORD_WRONG'), '', {
          timeOut: 25000,
        });
        break;
      default:
        this._toasterService.error(this.trans('PROFILE.ACCOUNT_DETAILS.PASSWORD_WRONG'));
        break;
    }
  }

  private instantiateChangeWalletPasswordForm(): void {
    this.changeWalletPasswordForm = new FormGroup(
      {
        currentWalletPassword: new FormControl('', [Validators.required]),
        newWalletPassword: new FormControl('', [Validators.required, Validators.minLength(9)]),
        confirmWalletPassword: new FormControl('', [Validators.required]),
      },
      [nativeFormControlValuesMustMatchValidator(['newWalletPassword', 'confirmWalletPassword'])],
    );
  }
}
