<div class="session-token-dialog">
  <p class="session-token-dialog_header heading2--bold">
    {{ trans('GET_SESSION_TOKEN.DIALOG_TITLE') }}
  </p>
  <p class="session-token-dialog_text body2--regular">
    {{ trans('GET_SESSION_TOKEN.DIALOG_SUBTITLE') }}
  </p>
  <div class="session-token-dialog__checkbox">
    <mat-checkbox id="agreeToTerms" name="agreeToTerms" [(ngModel)]="sessionAgreement">
    </mat-checkbox>
    <label
      class="session-token-dialog__checkbox_label caption1--bold"
      (click)="toggleAgreement()"
      for="agreeToTerms"
    >
      {{ trans('GET_SESSION_TOKEN.DIALOG_CHECK_LABEL') }}
    </label>
  </div>
  <button
    [disabled]="!sessionAgreement"
    class="body2--bold session-token-dialog_btn"
    (click)="onConfirm()"
  >
    {{ trans('GET_SESSION_TOKEN.DIALOG_CONFIRM_BTN') }}
  </button>
</div>
