import { inject, Injectable } from '@angular/core';
import { LogoutUseCase } from 'src/app/core/usecases/auth/logout.usecase';
import { DeleteAccountUseCase } from 'src/app/core/usecases/user/delete-account.usecase';
import { BasePresenter } from 'src/app/presentation/base/base.presenter';
import { PasswordCheckerSideEffect } from './password-checker.sideEffects';
import { PasswordCheckerViewEvents } from './password-checker.viewEvents';
import { PasswordCheckerViewState } from './password-checker.viewState';

@Injectable()
export class PasswordCheckerPresenter extends BasePresenter<
  PasswordCheckerViewState,
  PasswordCheckerViewEvents,
  PasswordCheckerSideEffect
> {
  private _deleteAccountUseCase = inject(DeleteAccountUseCase);

  private _logoutUseCase = inject(LogoutUseCase);

  protected defaultViewState(): PasswordCheckerViewState {
    return { shouldShowPassword: false };
  }

  protected onViewEvent(event: PasswordCheckerViewEvents): void {
    switch (event.type) {
      case 'ClickTogglePassword': {
        this.updateViewState({
          shouldShowPassword: (event.isShowPasswordClicked = !event.isShowPasswordClicked),
        });
        break;
      }
      case 'ClickConfirmDeleteAccount': {
        this._deleteAccountUseCase.execute(event.password).subscribe({
          next: () => {
            this._logoutUseCase.execute();

            this.emitSideEffect({
              type: 'ClosePasswordDialog',
            });
          },
          error: (err) => {
            if (err.error.errorCode === 'invalid_password') {
              this.emitSideEffect({
                type: 'ShowPasswordError',
                error: 'DELETE_ACCOUNT.ERROR',
              });
            } else if (err.error.errorCode === 'wallet_balance_found') {
              this.emitSideEffect({
                type: 'ShowWalletDialog',
              });
              this.emitSideEffect({
                type: 'ClosePasswordDialog',
              });
            } else {
              this.emitSideEffect({
                type: 'ShowPasswordError',
                error: 'ERRORS.GENERIC_ERROR_MESSAGE',
              });
            }
          },
        });
        break;
      }
    }
  }
}
