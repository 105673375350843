<div class="get-session-token">
  <!-- actions -->
  <div class="get-session-token__actions">
    <p class="get-session-token__actions_title caption1--regular">
      {{ trans('GET_SESSION_TOKEN.HEADER') }}
    </p>
    <!-- button -->
    <button
      *ngIf="!sessionAtom.get('agreed')"
      class="get-session-token__actions__reveal-btn"
      (click)="toggleDialog()"
    >
      <img
        src="assets/img/account-navigation-icons/eye-outline.svg"
        alt="illustration of an eye"
        class="get-session-token__actions__reveal-btn_icon"
      />
      <span class="body2--bold">
        {{ trans('GET_SESSION_TOKEN.REVEAL_BTN') }}
      </span>
    </button>

    <!-- session token with copy button -->
    <div
      class="get-session-token__actions__token-container"
      *ngIf="sessionAtom.get('sessionToken') && sessionAtom.get('agreed')"
    >
      <p class="get-session-token__actions__token-container_token">
        {{ sessionAtom.get('sessionToken') }}
      </p>
      <button
        class="get-session-token__actions__token-container_copy-btn"
        *ngIf="sessionAtom.get('sessionToken')"
        (click)="onCopy()"
      >
        <img src="assets/img/account-navigation-icons/copy.svg" alt="copy button icon" class="" />
      </button>
    </div>
  </div>
  <!-- hint -->
  <div class="get-session-token__hint">
    <img
      src="assets/img/account-navigation-icons/info-circle.svg"
      alt="copy button icon"
      class=""
    />
    <div>
      <p class="caption1--bold">{{ trans('GET_SESSION_TOKEN.HINT_TITLE') }}</p>
      <p class="get-session-token__hint_subTitle caption1--regular">
        {{ trans('GET_SESSION_TOKEN.HINT_SUBTITLE') }}
      </p>
    </div>
  </div>
</div>
