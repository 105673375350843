<div class="delete-account">
  <div class="delete-account__section">
    <img src="{{ assetsPath + 'user-remove.svg' }}" />
    <div>
      <p class="content-main-color body1--bold">{{ trans('DELETE_ACCOUNT.HEADER') }}</p>
      <p class="content-medium-color body2--regular">{{ trans('DELETE_ACCOUNT.RESULT') }}</p>
    </div>
  </div>
  <button
    class="delete-account__button body1--bold"
    (click)="presenter.emitViewEvent({ type: 'ClickDeleteAccount' })"
  >
    {{ trans('DELETE_ACCOUNT.HEADER') }}
  </button>
</div>
<p class="content-medium-color body2--regular">{{ trans('DELETE_ACCOUNT.WALLET_ENSURE') }}</p>
