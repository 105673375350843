<div class="personal-info">
  <app-profile-main-header
    [iconUrl]="assetsPath + '/img/personal-i.png'"
    [headerText]="trans('PROFILE.PERSONAL_INFO.TITLE')"
    [subHeaderText]="trans('PROFILE.PERSONAL_INFO.SUBTITLE')"
  ></app-profile-main-header>
  <ng-container
    *ngTemplateOutlet="user ? userAvailableTemplate : userNotAvailableTemplate"
  ></ng-container>
</div>

<!-- user is available -->
<ng-template #userAvailableTemplate>
  <form class="flex flex-column" [formGroup]="personalInformationForm">
    <div class="d-flex flex-column personal-info__user-available">
      <div class="horizontal-form-group">
        <div class="form-group form-group--disabled">
          <label class="form-group__default-label">{{
            trans('PROFILE.PERSONAL_INFO.TAAGER_ID')
          }}</label>
          <input
            class="confirm-input confirm-input--disabled"
            type="text"
            placeholder=""
            disabled="true"
            [value]="currentUser.id"
          />
        </div>
        <div class="form-group form-group--disabled">
          <label class="form-group__default-label">{{
            trans('PROFILE.PERSONAL_INFO.FULL_NAME')
          }}</label>
          <input
            class="confirm-input confirm-input--disabled"
            type="text"
            placeholder=""
            disabled="true"
            [value]="currentUser.fullName"
          />
        </div>
      </div>
      <div class="horizontal-form-group">
        <div class="form-group">
          <label class="form-group__default-label">{{
            trans('PROFILE.PERSONAL_INFO.FIRST_NAME')
          }}</label>
          <input class="confirm-input" type="text" placeholder="" formControlName="firstName" />
        </div>
        <div class="form-group">
          <label class="form-group__default-label">{{
            trans('PROFILE.PERSONAL_INFO.LAST_NAME')
          }}</label>
          <input class="confirm-input" type="text" placeholder="" formControlName="lastName" />
        </div>
      </div>
      <div class="horizontal-form-group">
        <div class="form-group form-group--disabled">
          <label class="form-group__default-label">{{
            trans('PROFILE.PERSONAL_INFO.PHONE_NUMBER')
          }}</label>
          <input
            class="confirm-input confirm-input--disabled"
            type="text"
            placeholder=""
            disabled="true"
            formControlName="phoneNum"
          />
        </div>
        <div class="form-group">
          <label class="form-group__default-label">{{
            trans('PROFILE.PERSONAL_INFO.E_MAIL')
          }}</label>
          <input
            class="confirm-input"
            type="text"
            placeholder=""
            formControlName="email"
            disabled
          />
        </div>
      </div>
    </div>
    <button
      [disabled]="personalInformationForm.invalid"
      class="btn-submit"
      [ngClass]="{
        'btn-submit--enabled': personalInformationForm.valid && personalInformationForm.dirty,
        'btn-submit--disabled': personalInformationForm.invalid || !personalInformationForm.dirty
      }"
      (click)="onEditPersonalInformationConfirmation()"
    >
      {{ trans('PROFILE.PERSONAL_INFO.SUBMIT') }}
    </button>
  </form>
</ng-template>

<!-- user is not available -->
<ng-template #userNotAvailableTemplate>
  TODO: Add user not available instructional text
</ng-template>
