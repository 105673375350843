import { inject, Injectable } from '@angular/core';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';
import { BasePresenter } from 'src/app/presentation/base/base.presenter';
import { GoToWalletSideEffect } from './go-to-wallet.sideEffects';
import { GoToWalletViewEvents } from './go-to-wallet.viewEvents';
import { GoToWalletViewState } from './go-to-wallet.viewState';

@Injectable()
export class GoToWalletPresenter extends BasePresenter<
  GoToWalletViewState,
  GoToWalletViewEvents,
  GoToWalletSideEffect
> {
  private _logMixpanelEventUseCase = inject(LogMixpanelEventUseCase);

  protected defaultViewState(): GoToWalletViewState {
    return {};
  }

  protected onViewEvent(event: GoToWalletViewEvents): void {
    switch (event.type) {
      case 'ClickCancel': {
        this._logMixpanelEventUseCase.execute({ eventName: 'delete_cancel_clicked' });
        this.emitSideEffect({
          type: 'CloseGoToWalletDialog',
        });
        break;
      }
      case 'ClickGoToWallet': {
        this._logMixpanelEventUseCase.execute({ eventName: 'delete_go_to_wallet_clicked' });
        this.emitSideEffect({
          type: 'NavigateToWallet',
        });
        break;
      }
    }
  }
}
