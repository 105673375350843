import { inject, Injectable } from '@angular/core';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';
import { BasePresenter } from 'src/app/presentation/base/base.presenter';
import { DeleteAccountSideEffect } from './delete-account.sideEffects';
import { DeleteAccountViewEvents } from './delete-account.viewEvents';
import { DeleteAccountViewState } from './delete-account.viewState';

@Injectable()
export class DeleteAccountPresenter extends BasePresenter<
  DeleteAccountViewState,
  DeleteAccountViewEvents,
  DeleteAccountSideEffect
> {
  private _logMixpanelEventUseCase = inject(LogMixpanelEventUseCase);

  protected defaultViewState(): DeleteAccountViewState {
    return {};
  }

  protected onViewEvent(event: DeleteAccountViewEvents): void {
    switch (event.type) {
      case 'ClickDeleteAccount': {
        this._logMixpanelEventUseCase.execute({ eventName: 'delete_button_clicked' });
        this.emitSideEffect({ type: 'ShowDialog' });
        break;
      }
    }
  }
}
