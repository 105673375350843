import { NgClass, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { user } from '@features/user/data';
import { localizationEvents } from '@mongez/localization';
import { TranslateModule } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserModel } from 'src/app/core/domain/user/user.model';
import { GetUserUseCase } from 'src/app/core/usecases/user/get-user2.usecase';
import { SetUserUseCase } from 'src/app/core/usecases/user/set-user-usecase';
import { UpdateProfileUseCase } from 'src/app/core/usecases/user/update-profile-usecase';
import { LocalizedComponent } from '../../base/localized.component';
import { ProfileMainHeaderComponent } from '../shared/components/profile-main-header/profile-main-header.component';
import { profileComponentsAssetsPathToken } from '../shared/config/profile.component.assets.path.config';

@Component({
  templateUrl: 'personal-info.component.html',
  selector: 'app-personal-info',
  styleUrls: ['personal-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ProfileMainHeaderComponent,
    NgTemplateOutlet,
    FormsModule,
    ReactiveFormsModule,
    NgClass,
    TranslateModule,
  ],
})
export class PersonalInfoComponent extends LocalizedComponent implements OnInit, OnDestroy {
  @Input() user: UserModel;

  @Output() userUpdated: EventEmitter<UserModel> = new EventEmitter();

  public personalInformationForm: UntypedFormGroup;

  private onDestroy$: Subject<boolean> = new Subject<boolean>();

  private profileFields: Array<string>;

  private successSettingUser = ' تم تعديل البيانات';

  private uploadInProgress = 'Upload in Progress.';

  private uploadComplete = 'Upload Complete.';

  private errorCompletingUpload = 'Error completing the upload! Please try again later.';

  public currentUser = user;

  constructor(
    private toast: ToastrService,
    private getUserUseCase: GetUserUseCase,
    private setUserUseCase: SetUserUseCase,
    private updateProfileUseCase: UpdateProfileUseCase,
    @Inject(profileComponentsAssetsPathToken) public assetsPath: string,
  ) {
    super();
  }

  ngOnInit(): void {
    localizationEvents.onChange('localeCode', () => {
      this.changeDetectorRef.detectChanges();
    });
    this.instantiatePersonalInformationForm();
    if (this.user) {
      this.patchPersonalInformationForm(this.user);
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
    this.unsubscribeAll();
  }

  instantiatePersonalInformationForm(): void {
    this.personalInformationForm = new UntypedFormGroup({
      firstName: new UntypedFormControl('', [Validators.required]),
      lastName: new UntypedFormControl('', [Validators.required]),
      phoneNum: new UntypedFormControl('', [Validators.required]),
      email: new UntypedFormControl('', [Validators.email, Validators.required]),
    });
    this.profileFields = ['firstName', 'lastName', 'phoneNum', 'email'];
  }

  patchPersonalInformationForm(data: any): void {
    this.profileFields.forEach((field) =>
      this.personalInformationForm.get(field)?.patchValue(data[field]),
    );
  }

  public onEditPersonalInformationConfirmation(): void {
    if (this.personalInformationForm.valid && this.personalInformationForm.dirty) {
      this.updateProfileUseCase
        .execute(this.personalInformationForm.value)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe({
          next: () => {
            const retrievedUser = this.returnDeepCopy(this.getUserUseCase.execute());
            const updatedUser = { ...retrievedUser, ...this.personalInformationForm.value };
            this.setUser(updatedUser);
            this.userUpdated.emit(updatedUser);
            // this.userProfile = updatedUser;
          },
          error: (error) => this.toast.error(error.error.msg),
        });
    }
  }

  /**
   *
   * @param userData
   *
   * shared local method for setting the user.
   */
  private setUser(userData: UserModel): void {
    this.setUserUseCase.execute(userData);
    this.toast.success(this.successSettingUser);
  }

  private returnDeepCopy(referenceObject: any): any {
    return JSON.parse(JSON.stringify(referenceObject));
  }
}
