import { createAtom } from '@mongez/atom';

type Session = {
  agreed: boolean;
  sessionToken: string;
};

export const sessionAtom = createAtom<Session>({
  key: 'sessionAgreement',
  default: { agreed: false, sessionToken: '' },
});
