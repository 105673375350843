import { Component, inject } from '@angular/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { BaseComponent } from '../../base/base.component';
import { PasswordCheckerDialogComponent } from './password-checker-dialog/password-checker-dialog.component';
import { DeleteAccountPresenter } from './presenter/delete-account.presenter';
import { DeleteAccountSideEffect } from './presenter/delete-account.sideEffects';
import { DeleteAccountViewEvents } from './presenter/delete-account.viewEvents';
import { DeleteAccountViewState } from './presenter/delete-account.viewState';

@Component({
  selector: 'app-delete-account',
  standalone: true,
  imports: [MatDialogModule],
  templateUrl: './delete-account.component.html',
  styleUrls: ['./delete-account.component.scss'],
  providers: [DeleteAccountPresenter],
})
export class DeleteAccountComponent extends BaseComponent<
  DeleteAccountPresenter,
  DeleteAccountViewState,
  DeleteAccountViewEvents,
  DeleteAccountSideEffect
> {
  public assetsPath = '../../../../assets/img/';

  public presenter = inject(DeleteAccountPresenter);

  private _dialog: MatDialog = inject(MatDialog);

  onSideEffect(sideEffect: DeleteAccountSideEffect): void {
    switch (sideEffect.type) {
      case 'ShowDialog': {
        this._dialog.open(PasswordCheckerDialogComponent, {
          width: '500px',
          direction: this.direction,
        });
        break;
      }
    }
  }
}
