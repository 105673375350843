<div class="change-wallet-password">
  <div class="change-wallet-password__main-header">
    <div class="change-wallet-password__main-header__instructions">
      <app-profile-main-header
        [headerText]="trans('PROFILE.CHANGE_WALLET_PASSWORD.TITLE')"
        [subHeaderText]="trans('PROFILE.CHANGE_WALLET_PASSWORD.SUBTITLE')"
        [iconUrl]="assetsPath + '/img/password-i.png'"
      ></app-profile-main-header>
    </div>
  </div>

  <form [formGroup]="changeWalletPasswordForm">
    <div class="form-group">
      <label class="form-group__default-label">{{
        trans('PROFILE.CHANGE_WALLET_PASSWORD.CURRENT_PASSWORD')
      }}</label>
      <input
        class="form-control"
        type="password"
        placeholder=""
        formControlName="currentWalletPassword"
      />
      <ng-template
        [ngTemplateOutlet]="errorWrapperTemplate"
        [ngTemplateOutletContext]="{
          controlName: 'currentWalletPassword',
          errorMapping: getErrorMappingForFormFields('currentWalletPassword')
        }"
      ></ng-template>
    </div>
    <div class="horizontal-form-group">
      <div class="form-group">
        <label class="form-group__default-label">{{
          trans('PROFILE.CHANGE_WALLET_PASSWORD.NEW_PASSWORD')
        }}</label>
        <input
          class="confirm-input"
          type="password"
          placeholder=""
          formControlName="newWalletPassword"
        />
        <ng-template
          [ngTemplateOutlet]="errorWrapperTemplate"
          [ngTemplateOutletContext]="{
            controlName: 'newWalletPassword',
            errorMapping: getErrorMappingForFormFields('newWalletPassword')
          }"
        ></ng-template>
      </div>
      <div class="form-group">
        <label class="form-group__default-label">{{
          trans('PROFILE.CHANGE_WALLET_PASSWORD.CONFIRM_NEW_PASSWORD')
        }}</label>
        <input
          class="confirm-input"
          type="password"
          placeholder=""
          formControlName="confirmWalletPassword"
        />
        <ng-template
          [ngTemplateOutlet]="errorWrapperTemplate"
          [ngTemplateOutletContext]="{
            controlName: 'confirmWalletPassword',
            errorMapping: getErrorMappingForFormFields('confirmWalletPassword')
          }"
        ></ng-template>
      </div>
    </div>
  </form>
  <button class="btn-submit btn-submit--enabled" (click)="onSubmitChangeWalletPasswordForm()">
    {{ trans('PROFILE.CHANGE_WALLET_PASSWORD.SUBMIT') }}
  </button>
</div>

<!-- shared templates -->
<ng-template #errorWrapperTemplate let-controlName="controlName" let-errorMapping="errorMapping">
  <app-form-field-error
    [showErrors]="changeWalletPasswordFormIsSubmitted"
    [form]="changeWalletPasswordForm"
    [controlName]="controlName"
    [errorMapping]="errorMapping"
  ></app-form-field-error>
</ng-template>
