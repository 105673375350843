<div class="password-checker">
  <img src="{{ assetsPath + 'heart-circle.svg' }}" />
  <p class="content-main-color heading2--bold">{{ 'DELETE_ACCOUNT.MISS_YOU' | translate }}</p>
  <p class="content-main-color body2--medium">{{ 'DELETE_ACCOUNT.ENTER_PASSWORD' | translate }}</p>
  <form [formGroup]="deleteAccountForm" class="password-checker-form">
    <div class="password-checker__input">
      <input
        class="password-checker__field body2--medium"
        [type]="viewState.shouldShowPassword ? 'text' : 'password'"
        placeholder="{{ 'DELETE_ACCOUNT.PASSWORD' | translate }}"
        formControlName="password"
      />
      <img
        loading="lazy"
        class="password-checker__eye"
        src="{{
          viewState.shouldShowPassword
            ? assetsPath + '/auth/eye-slash.svg'
            : assetsPath + '/auth/eye.svg'
        }}"
        (click)="
        presenter.emitViewEvent({
          type: 'ClickTogglePassword',
          isShowPasswordClicked: viewState.shouldShowPassword,
        })
      "
      />
    </div>
    <button
      class="password-checker__button body2--bold"
      [disabled]="deleteAccountForm.invalid"
      (click)="
        presenter.emitViewEvent({
          type: 'ClickConfirmDeleteAccount',
          password: deleteAccountForm.value.password!
        })
      "
    >
      {{ 'DELETE_ACCOUNT.CONFIRM' | translate }}
    </button>
  </form>
  <p class="content-medium-color caption1--regular">{{ 'DELETE_ACCOUNT.LOSE_DATA' | translate }}</p>
</div>
