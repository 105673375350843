import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { provideRouter } from '@angular/router';
import { AccountComponent } from './account.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { PersonalInfoComponent } from './personal-info/personal-info.component';
import { ReferredAccountComponent } from './referred-account/referred-account.component';
import { FormFieldErrorComponent } from './shared/components/form-field-error/form-field-error.component';
import { ProfileMainHeaderComponent } from './shared/components/profile-main-header/profile-main-header.component';
import {
  profileComponentsAssetsPath,
  profileComponentsAssetsPathToken,
} from './shared/config/profile.component.assets.path.config';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    AccountComponent,
    PersonalInfoComponent,
    ChangePasswordComponent,
    ReferredAccountComponent,
    FormFieldErrorComponent,
    ProfileMainHeaderComponent,
  ],
  providers: [
    { provide: profileComponentsAssetsPathToken, useValue: profileComponentsAssetsPath },
    provideRouter([{ path: '', component: AccountComponent }]),
  ],
})
export class ProfileModule {}
