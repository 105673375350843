<div class="change-password">
  <div class="change-password__main-header">
    <div class="change-password__main-header__main-header-instructions">
      <app-profile-main-header
        [headerText]="trans('PROFILE.CHANGE_PASSWORD.TITLE')"
        [subHeaderText]="trans('PROFILE.CHANGE_PASSWORD.SUBTITLE')"
        [iconUrl]="assetsPath + '/img/password-i.png'"
      ></app-profile-main-header>
    </div>
  </div>
  <form [formGroup]="changePasswordForm">
    <div class="form-group">
      <label class="form-group__default-label">{{
        trans('PROFILE.CHANGE_PASSWORD.CURRENT_PASSWORD')
      }}</label>
      <input
        class="form-control form-control--default-input"
        type="password"
        placeholder=""
        formControlName="currentPassword"
      />
      <ng-template
        [ngTemplateOutlet]="errorWrapperTemplate"
        [ngTemplateOutletContext]="{
          errors: returnFormFieldErrorsAsAnArray(
            changePasswordForm.get('currentPassword')!.errors!
          ),
          errorMapping: getErrorMappingForFormFields('currentPassword')
        }"
      ></ng-template>
    </div>
    <div class="horizontal-form-group">
      <div class="form-group">
        <label class="form-group__default-label">{{
          trans('PROFILE.CHANGE_PASSWORD.NEW_PASSWORD')
        }}</label>
        <input class="confirm-input" type="password" placeholder="" formControlName="newPassword" />
        <ng-template
          [ngTemplateOutlet]="errorWrapperTemplate"
          [ngTemplateOutletContext]="{
            errors: returnFormFieldErrorsAsAnArray(
              changePasswordForm.get('newPassword')!.errors!,
              changePasswordForm.errors!
            ),
            errorMapping: getErrorMappingForFormFields('newPassword')
          }"
        ></ng-template>
      </div>
      <div class="form-group">
        <label class="form-group__default-label">{{
          trans('PROFILE.CHANGE_PASSWORD.CONFIRM_NEW_PASSWORD')
        }}</label>
        <input
          class="confirm-input"
          type="password"
          placeholder=""
          formControlName="confirmPassword"
        />
        <ng-template
          [ngTemplateOutlet]="errorWrapperTemplate"
          [ngTemplateOutletContext]="{
            errors: returnFormFieldErrorsAsAnArray(
              changePasswordForm.get('confirmPassword')!.errors!,
              changePasswordForm.errors!
            ),
            errorMapping: getErrorMappingForFormFields('confirmPassword')
          }"
        ></ng-template>
      </div>
    </div>
  </form>
  <button class="btn-submit btn-submit--enabled" (click)="onSubmitChangePasswordForm()">
    {{ trans('PROFILE.CHANGE_PASSWORD.SUBMIT') }}
  </button>
</div>

<!-- shared templates -->
<ng-template #errorWrapperTemplate let-errors="errors" let-errorMapping="errorMapping">
  <div *ngIf="changePasswordFormIsSubmitted" class="input-error">
    <span *ngFor="let error of errors">
      <ng-container [ngSwitch]="error">
        <ng-template
          *ngSwitchCase="'required'"
          [ngTemplateOutlet]="fieldIsRequiredTemplate"
          [ngTemplateOutletContext]="{ label: errorMapping['required'] }"
        ></ng-template>
        <ng-template
          *ngSwitchCase="'fieldsThatMustMatchDoMatchError'"
          [ngTemplateOutlet]="newPasswordMustMatchConfirmPasswordTemplate"
          [ngTemplateOutletContext]="{ label: errorMapping['fieldsThatMustMatchDoMatchError'] }"
        ></ng-template>
        <ng-template
          *ngSwitchCase="'minlength'"
          [ngTemplateOutlet]="passwordMustBeAtleastNCharactersLongTemplate"
          [ngTemplateOutletContext]="{ label: errorMapping['minlength'] }"
        ></ng-template>
      </ng-container>
    </span>
  </div>
</ng-template>

<ng-template #fieldIsRequiredTemplate let-label="label">
  <span class="block-element">{{ label }}</span>
</ng-template>

<ng-template #newPasswordMustMatchConfirmPasswordTemplate let-label="label">
  <span class="block-element">{{ label }}</span>
</ng-template>

<ng-template #passwordMustBeAtleastNCharactersLongTemplate let-label="label">
  <span class="block-element">{{ label }}</span>
</ng-template>
