import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { mixPanelEvent } from '@features/activities/data';
import { user } from '@features/user/data';
import { dialogAtom } from '@presentation/shared/dialog';
import { sessionAtom } from '@presentation/user/atoms/account';
import { LocalizedComponent } from 'app/presentation/base/localized.component';

@Component({
  selector: 'app-get-session-token-dialog',
  standalone: true,
  imports: [CommonModule, MatCheckboxModule, FormsModule],
  templateUrl: './get-session-token-dialog.component.html',
  styleUrls: ['./get-session-token-dialog.component.scss'],
})
export class GetSessionTokenDialogComponent extends LocalizedComponent {
  public sessionAgreement = sessionAtom.get('agreed');

  public toggleAgreement(): void {
    this.sessionAgreement = !this.sessionAgreement;
  }

  public onConfirm(): void {
    sessionAtom.update({
      sessionToken: user.toEncryptedString(),
      agreed: this.sessionAgreement,
    });

    dialogAtom.close('get-session-token-dialog');
    mixPanelEvent('session_token_copied');
  }
}
