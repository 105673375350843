import { Component, inject } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from 'src/app/presentation/base/base.component';
import { GoToWalletDialogComponent } from '../go-to-wallet-dialog/go-to-wallet-dialog.component';
import { PasswordCheckerPresenter } from './presenter/password-checker.presenter';
import { PasswordCheckerSideEffect } from './presenter/password-checker.sideEffects';
import { PasswordCheckerViewEvents } from './presenter/password-checker.viewEvents';
import { PasswordCheckerViewState } from './presenter/password-checker.viewState';

@Component({
  selector: 'app-password-checker-dialog',
  standalone: true,
  imports: [TranslateModule, FormsModule, ReactiveFormsModule, GoToWalletDialogComponent],
  templateUrl: './password-checker-dialog.component.html',
  styleUrls: ['./password-checker-dialog.component.scss'],
  providers: [PasswordCheckerPresenter],
})
export class PasswordCheckerDialogComponent extends BaseComponent<
  PasswordCheckerPresenter,
  PasswordCheckerViewState,
  PasswordCheckerViewEvents,
  PasswordCheckerSideEffect
> {
  public assetsPath = '../../../../assets/img/';

  public deleteAccountForm = new FormGroup({
    password: new FormControl('', [Validators.required]),
  });

  public presenter: PasswordCheckerPresenter = inject(PasswordCheckerPresenter);

  constructor(
    private _toastr: ToastrService,
    private _dialog: MatDialog,
    private _dialogRef: MatDialogRef<PasswordCheckerDialogComponent>,
  ) {
    super();
  }

  onSideEffect(sideEffect: PasswordCheckerSideEffect): void {
    switch (sideEffect.type) {
      case 'ShowPasswordError': {
        this._toastr.error(this.trans(sideEffect.error));
        break;
      }
      case 'ClosePasswordDialog': {
        this._dialogRef.close();
        break;
      }
      case 'ShowWalletDialog': {
        this._dialog.open(GoToWalletDialogComponent, { width: '650px' });
        break;
      }
    }
  }
}
